<!--
 * @Author: your name
 * @Date: 2020-12-11 14:25:48
 * @LastEditTime: 2021-02-04 08:34:09
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/views/CertifiedPage/CardCertifiedList.vue
-->
<template>
    <div>
        <div class="cardCertifiedList">
            <van-cell-group>
                <van-field v-model="dataInfo.realName" label="姓名" placeholder="请输入姓名" input-align="right" readonly/>
                <van-field v-model="dataInfo.idNo" label="身份证" placeholder="请输入身份证" input-align="right" readonly/>
                <van-field v-model="dataInfo.phone" label="手机号" placeholder="请输入手机号" input-align="right" readonly right-icon="arrow" @click="gotoPhone"/>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import { mapState } from "vuex";
import { getInfo } from "../../api/home";

export default {
    name: 'CardCertifiedList',
    data() {
        return {
            dataInfo: {
                realName: '',
                idNo: '',
                phone: ''
            },
            phone: ''
        }
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        async getInfo() {
            const {status, resultData} = await getInfo();
            if (status === 'complete') {
                this.phone = resultData.phone;
                resultData.phone  = resultData.phone.replace(/^(.{3})(?:\d+)(.{2})$/,  "\$1****\$2");
                resultData.idNo = resultData.idNo.replace(/^(.{3})(?:\d+)(.{2})$/,  "\$1****\$2");
                this.dataInfo = resultData;
            }
        },
        async gotoPhone() {
            this.$router.push({
                name: 'ChangeCurrentPhone',
                path: '/Setting/changeCurrentPhone',
                query: {
                    phone: this.phone
                }
            })
            await store.dispatch('user/savePathUrl', { url: '/CertifiedPage/CardCertifiedList' })
        },
    }
}
</script>
